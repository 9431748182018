import React from 'react';
import { Link } from 'react-router-dom';
import './InternshipWrapper.css';

const InternshipWrapper = () => {
  return (
    <div className="internship-wrapper">
      <section>
      <div className="category">
        <Link to="https://rzp.io/rzp/30JYtSI" className="category-link">
        First Year: Freshman  
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/30JYtSI" className="category-link">
        Second Year: Sophomore 
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/30JYtSI" className="category-link">
        Third Year: Junior  
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/30JYtSI" className="category-link">
        Fourth Year: Senior  
        </Link>
      </div>
      </section>
    </div>
  );
};

export default InternshipWrapper;
